// Spin animation
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// Slide down animation
@-webkit-keyframes slideDown {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(100%);
        opacity: 0;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(100%);
        opacity: 0;
    }
}

@-webkit-keyframes slideDown2 {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideDown2 {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

// Slide up animation
@-webkit-keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes slideUp2 {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}

@keyframes slideUp2 {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import './scssUtilities/animations';
@import './scssUtilities/mixins';
@import './scssUtilities/variables';
@import './scssUtilities/fonts';
@import './scssUtilities/forms';
@import './scssUtilities/toast';
@import './scssUtilities/ionic.scss';

.ios-fix {
    padding-top: env(safe-area-inset-top);
}

:root {
    --ion-safe-area-top: 2.5rem;
}

:root.ios {
    --ion-safe-area-bottom: 1rem;
}

:root {
    --ion-safe-area-top: env(safe-area-inset-top) !important;
    --ion-safe-area-bottom: env(safe-area-inset-bottom) !important;
}



html,
body,
ion-content {
    overflow-x: hidden;
    /* Prevents horizontal scrolling */
    overscroll-behavior-y: contain;
    /* Prevents unwanted bounce effect */
}

body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-family: var(--primary-regular-font);
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
}


.ios {

    // My component custom header
    app-header {
        top: 0px;
        position: relative;
        width: 100%;
        z-index: 10000;
    }

    ion-header {
        background: #0127A5;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
        --ion-safe-area-top: 2px;
        --ion-safe-area-bottom: 20px;
    }

    ion-content {
        // It might not be necessary, you need to test it in the application, in my case, I needed it.
        --offset-top: 15px !important;
    }
}

.safari-browser {
    .chart-container {
        apx-chart {
            position: relative;
            top: -6px;
        }
    }
}



// General
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
label,
a,
button,
li,
mat-panel-title,
legend,
.mat-expansion-panel-content,
.mat-typography {
    font-family: var(--primary-regular-font);
}

.cdk-overlay-dark-backdrop {
    background: rgba(7, 7, 7, 0.65);
}


ion-skeleton-text {
    --border-radius: 9999px;
    --background: rgba(0, 0, 0, 0.06);
    --background-rgb: 0, 0, 0;
}

.loadingSkeleton {
    width: 100%;
    display: block;
    text-align: center;
}



.main {
    @media screen and (max-width:991px) {
        padding-bottom: var(--mobileTabHeight);
    }
}

.primary-general-btn {
    text-decoration: none;
    color: var(--primary-btn-text);
    background: var(--primary-btn-bg);
    @include setHeightWidth(100%, 100%);
    text-transform: none;
    // box-shadow: 0px 5px 6px var(--primary-btn-shadow);
    box-shadow: none;
    border-radius: 28px;
    opacity: 1;
    height: 44.25px;
    outline: none;
    // border: none;
    text-align: center;
    font-variant: normal;
    font-style: normal;
    font-size: calc(13/16)+rem;
    line-height: 46px;
    font-family: var(--primary-demiBold-font);
    letter-spacing: 0px;
    opacity: 1;
    display: block;
    // padding: 22px 28px;
    @include displayFlex(row, center, center);
    margin: 30px 0;
    @include transitionAllEaseInOut;

    @media(min-width: 1600px) {
        font-size: calc(16/16)+rem;
        height: 59px;
    }

    @media(min-width: 768px) {
        width: 100%;
    }


    --border-radius: 28px;
    // --box-shadow: 0px 5px 6px var(--primary-btn-shadow);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ternaryColor);
    --box-shadow: none;
    --padding-top: 18px;
    --padding-bottom: 18px;
    --padding-left: 28px;
    --padding-right: 28px;
    --color: var(--primary-btn-text);
    --background: var(--primary-btn-bg);
    --background-hover: var(--primary-btn-bg);
    --background-activated: var(--primary-btn-bg);
    --background-focused: var(--primary-btn-bg);

    &.whiteBtn {
        color: var(--ternaryColor);
        // background: var(--white);
        font-size: calc((13/16) * 1rem);
        line-height: calc((25/16) * 1rem);
        border: 1px solid transparent;
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--ternaryColor);
        --color: var(--ternaryColor);
        --background: var(--white);
        --background-hover: var(--primary-btn-bg);
        --background-activated: var(--primary-btn-bg);
        --background-focused: var(--primary-btn-bg);
        width: 60%;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        will-change: transform;

        &:hover,
        &:active,
        &:focus {
            color: var(--white);
            // background: var(--ternaryColor) !important;
            --background: var(--ternaryColor) !important;
        }

        @media screen and (min-width:1600px) {
            font-size: calc((16/16) * 1rem);
            line-height: calc((32/16) * 1rem);
        }

        .btnIcon {
            font-size: 18px;
            line-height: 1.5;
            margin-right: 5px;

            @media screen and (min-width:1600px) {
                font-size: 20px;
            }
        }
    }
}

.signout-confirmation-btn {
    padding: 0;
    height: 45px;
    color: var(--primary-btn-text);
    background-color: var(--secondary-btn-bg);
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-left: 28px;
    --padding-right: 28px;
    --color: var(--primary-btn-text);
    --background: var(--secondary-btn-bg);
    --background-hover: var(--primary-btn-text);
    --background-activated: var(--secondary-btn-bg);
    --background-focused: var(--secondary-btn-bg);

    &:hover,
    &:active,
    &:focus,
    &:visited {
        color: var(--primary-btn-text);
        background-color: var(--primary-btn-bg);
    }
}

.miniActionBtn {
    text-decoration: none;
    color: var(--ternaryColor);
    background: var(--white);
    text-transform: none;
    // box-shadow: 0px 5px 6px var(--primary-btn-shadow);
    box-shadow: none;
    border-radius: 0;
    opacity: 1;
    @include setHeightWidth(32px, 100%);
    outline: none;
    text-align: center;
    font-variant: normal;
    font-style: normal;
    font-size: calc(15/16)+rem;
    line-height: 25px;
    font-family: var(--primary-regular-font);
    letter-spacing: 0px;
    opacity: 1;
    display: block;
    @include displayFlex(row, center, center);
    margin: 0px 0 31.5px;
    --border-radius: 0px;
    --border-width: 0;
    border-top: 1px solid var(--grey14);
    border-bottom: 1px solid var(--grey14);
    border-left: none;
    border-right: none;
    --background: transparent;
    --box-shadow: none;
    --padding-top: 7.8px;
    --padding-bottom: 7.8px;
    --padding-left: 28px;
    --padding-right: 28px;
    --color: var(--primary-btn-text);
    --background-hover: var(--primary-btn-bg);
    --background-activated: var(--primary-btn-bg);
    --background-focused: var(--primary-btn-bg);
}

.versionNumber {
    height: 50px;
    // background-color: rgba(0, 64, 186, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
        margin-bottom: 0;
        font-size: 16px;
        font-family: var(--primary-regular-font);
        font-weight: normal;
        color: var(--primary-footer-textWithOpacity5);
    }

}

.disableColor {
    background-color: transparent;
}

.text-right {
    text-align: right !important;
}

// Material Checkbox CSS
.mat-mdc-checkbox {
    width: 100%;

    .mdc-form-field {

        .mdc-checkbox,
        input {
            --mdc-checkbox-state-layer-size: 20px;
            --mat-full-pseudo-checkbox-selected-icon-color: var(--primaryCheckbox) !important;
            --mat-full-pseudo-checkbox-selected-icon-color: var(--primaryCheckbox) !important;
            --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primaryCheckbox) !important;
            --mat-minimal-pseudo-checkbox-selected-icon-color: var(--primaryCheckbox) !important;
            --mat-option-selected-state-label-text-color: var(--primaryCheckbox) !important;
            --mdc-checkbox-selected-focus-icon-color: var(--primaryCheckbox) !important;
            --mdc-checkbox-selected-hover-icon-color: var(--primaryCheckbox) !important;
            --mdc-checkbox-selected-icon-color: var(--primaryCheckbox) !important;
            --mdc-checkbox-selected-pressed-icon-color: var(--primaryCheckbox) !important;
            --mdc-checkbox-selected-focus-state-layer-color: var(--primaryCheckbox) !important;
            --mdc-checkbox-selected-hover-state-layer-color: var(--primaryCheckbox) !important;
            --mdc-checkbox-selected-pressed-state-layer-color: var(--primaryCheckbox) !important;

        }

        .mdc-checkbox__background {
            --mdc-checkbox-selected-icon-color: var(--primaryCheckbox) !important;
        }

        .mdc-label {
            color: var(--primaryColor);
            font-size: 14px;
            font-family: var(--primary-regular-font);
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0px;
            opacity: 1;
            text-align: left;
        }
    }
}

.mat-datepicker-popup .mat-calendar-content .mat-calendar-body-selected {
    --mat-datepicker-calendar-date-selected-state-background-color: var(--secondaryColor);
}

// begin:: Toaster CSS
.toast-container {
    bottom: 10px !important;

    &.toast-top-center .ngx-toastr,
    &.toast-bottom-center .ngx-toastr {
        // width: 400px !important;
        min-width: 200px !important;

        @media screen and (max-width:400px) {
            min-width: unset !important;
            width: 90% !important;
        }
    }
}

// end:: Toaster CSS


.mouse-pointerz-nill {
    cursor: default !important;
}

.grayedBtnContainer {
    border: none !important;

    td {
        border: none !important;
        background: transparent !important;
        padding-bottom: 0 !important;

        &:focus {
            background: transparent;
        }


    }

    button {
        width: 100%;
        margin: 0 auto;
        background-color: var(--grayed-btn-bg);
        margin-top: calc(25/16)+rem;
    }
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.mob-display-on {
    display: none;
}

.mat-mdc-form-field-error {
    display: block;
    font-family: var(--primary-regular-font);
    color: var(--danger2) !important;
    font-size: calc(12/16)+rem;
}

.menu-item2 {
    >* {
        color: var(--menuLinks);

        &:active,
        &:focus {
            background: transparent;
        }
    }
}

.ant-popover {
    .ant-popover-content {
        .ant-popover-arrow {
            display: none;
        }

        .ant-popover-inner {
            padding: calc(11/16)+rem;
            border-radius: calc(6/16) +rem;
        }

        .ant-popover-title {
            color: var(--white);
            font-size: calc(16/16) +rem;
            padding: calc(10/16)+rem calc(15/16)+rem;
            border: none;
            background-color: var(--secondaryColor2);
            border-radius: calc(8/16)+rem;
        }

        .ant-popover-inner-content {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            .link,
            a {
                display: block;
                @include displayFlex(row, flex-start, center);
                font-size: calc(18/16) + rem;
                font-weight: normal;
                padding: calc(15/16) + rem calc(10/16) +rem;
                margin: 0;
                text-decoration: none;
                cursor: pointer;
                border: 1px solid transparent;
                @include transitionAllEaseInOut;

                span {
                    color: var(--font-faded);
                }

                &:hover,
                &:active {

                    // background-color: var(--grey5);
                    border-color: var(--primary-border);
                    border-radius: calc(8/16) + rem;

                    .icon {
                        color: var(--secondaryColor2);

                        path {
                            fill: var(--secondaryColor2);
                        }
                    }

                    span {
                        color: var(--secondaryColor2);
                        text-decoration: none;
                    }
                }


                .icon {
                    color: var(--font-faded);

                    path {
                        fill: var(--font-faded);
                    }
                }

                span {
                    margin-left: 15px;
                }
            }
        }
    }
}

.headerMenuPopup {
    max-width: 385px;

    .ant-popover-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 8px 10px 6px 10px !important;
    }
}

.modalCrossBtn {
    @include setHeightWidth(40px, (40/16)+rem);
    @include displayFlex(row, center, center);
    border-radius: 50%;
    position: absolute;
    left: (20/16)+rem;
    top: (20/16)+rem;
    padding: 0;
    cursor: pointer;
    margin: 0 0 0 auto;
    background-color: var(--primary-bg);

    @media(max-width: 767px) {
        // @include setHeightWidth(auto, (18/16)+rem);
        order: 2;
        margin: 0 0 0 auto;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .actionIcon {
        margin: 0 auto;
    }

}

.transactions-card {

    .ant-table-column-sorter-down.active,
    .ant-table-column-sorter-up.active {
        color: var(--ternary1);
    }

    .ant-table-thead th.ant-table-column-sort,
    .ant-tabs-dropdown-menu-item:hover,
    .ant-table-thead>tr>th {
        color: var(--primary-help-text);
        background-color: var(--white);
        text-transform: uppercase;
        font-family: var(--primary-regular-font);
        font-weight: normal;
        font-size: 13px;
        line-height: 1.5;
    }

    .ant-table-thead>tr>th:before {
        display: none;
    }

    tr.ant-table-expanded-row:hover>td,
    tr.ant-table-expanded-row>td {
        background: var(--white);
    }

    .outerTable {
        .ant-table-tbody tr.active {
            td {
                border-bottom-color: transparent;
            }
        }

        .ant-table-pagination.ant-pagination {
            padding: 0 calc(20/16)+rem;
            display: none;
        }
    }



    // Child table
    .ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
        margin: -16px 0px 15px 0px;
    }

    .ant-table.ant-table-middle {

        .ant-table-thead>tr>th,
        .ant-table-tbody>tr>td {
            background-color: var(--ternary4);
            padding: 15px 8px;
        }

        .ant-table-thead {
            tr {
                th {
                    border-bottom: 2px solid var(--primary-border);
                    white-space: nowrap;

                    &:first-child {
                        border-top-left-radius: 10px;
                        padding-left: 18px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }
        }

        .ant-table-tbody {
            tr {
                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 10px !important;
                        }

                        &:last-child {
                            border-bottom-right-radius: 10px !important;
                        }
                    }
                }
            }
        }

    }

    .paymentsTable-mob {
        .mobileCards {
            &.mat-expanded {
                .expansion-header {
                    background-color: var(--ternary4);
                }
            }

            .expansion-header {
                @include displayFlex(row, center, center);
                margin: 0;
                padding: 0.75rem !important;

                .mat-expansion-indicator {
                    @include displayFlex(row, center, center);
                }
            }

            .mat-expansion-panel-body {
                padding: 0;
                @include displayFlex(row, center, center);
            }
        }
    }

}

.understandPricingPage,
.multipleOffersWrapper {
    .ant-table-thead>tr>th {
        font-weight: bold;
        text-transform: capitalize;
        background: rgba(0, 64, 186, 0.05) !important;
    }
}

.information {
    margin-bottom: 20px;
    padding: 0.75rem;
    background-color: var(--ternary4);
    @include displayFlex(row, center, center);
    border-radius: 5px;


    .content1 {
        padding: 0 0 10px;
        text-align: left;

        .transactionName {
            text-transform: uppercase;
        }
    }


    .content2 {
        @include displayFlex(row, space-between, center);
    }

    .currencyContainer {
        // padding: 0.75rem 0rem;
        vertical-align: middle;
        background-color: var(--headerIconsBg);
        @include setHeightWidth(100%, 100%);
        @include displayFlex(row, center, center);

        .currency {
            color: var(--ternary3);
            font-weight: bold;
            text-align: center;
        }
    }
}


/* Data Card */
.transactions-card {
    display: block;
    background: var(--primary-card-bg) 0% 0% no-repeat padding-box;
    box-shadow: -0.28px 4px 6px var(--secondary-bg-shadow);
    border-radius: 12px;
    opacity: 1;
    padding: 16.5px 0px;
    // padding: 0.5rem;
    position: relative;
    // margin: 40px 0 25px;
    margin: 22px 0 25px;
    height: max-content;

    // temp
    @media(min-width: 768px) {
        min-height: 50vh;
    }

    @media(max-width: 767px) {
        padding: 16.5px 12px;
    }

    .card-header {
        padding: 0.75rem 1.25rem;
        background: transparent linear-gradient(90deg, var(--primaryColor2) 0%, var(--secondaryColor3) 100%) 0% 0% no-repeat padding-box;
        box-shadow: -0.49px 7px 13px #1D61964A;
        border-radius: 6px;
        position: relative;
        top: -40px;
        left: 0;
        transform: translate(0px, 0px);
        margin: 0px 12px -20px;

        @media(max-width: 767px) {
            margin: 0px 0px -20px;
        }

        .title {
            text-align: left;
            font: normal normal normal calc(14/16) +rem/calc(24/16) +rem var(--primary-regular-font);
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            margin: 0;
            text-transform: capitalize;

            @media screen and (min-width:1600px) {
                font-size: calc(20/16) +rem;
                line-height: calc(34/16) +rem;
            }
        }
    }

    .card-body {
        padding: 0;
    }
}


/* Media Queries */
@media screen and (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1598px;
    }
}

@media(min-width: 1200px) {
    .p-none-pad {
        padding: 0;
    }

    .web-display-off {
        display: none !important;
    }
}

@media (max-width:1199px) {
    .pad-display-off {
        display: none !important;
    }

    .mob-display-on {
        display: block;
    }

    .pad-hidden {
        display: none;
    }

    .mobile-card {
        // flex: 0 0 33.333333%;
        width: 33.3%;
        flex-basis: unset;
        flex-grow: unset;
        padding-left: 0.219rem !important;
        padding-right: 0.219rem !important;

    }

    .mobile-card-100 {
        width: 100%;
    }
}

@media(min-width:992px) {
    .web-display-off2 {
        display: none !important;
    }
}

@media(min-width:768px) {
    .web-display-off3 {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .mob-display-off {
        display: none;
    }

    .mob-pad-0 {
        padding: 0;
    }

    // begin:: ios fixes
    .ios,
    .plt-iphone,
    .platform-android,
    .plt-android {
        .notification-bar {
            padding-top: calc(var(--ion-safe-area-top) + 10px) !important;
            padding-bottom: calc(var(--ion-safe-area-bottom) + 0px) !important;

            .contentContainer {
                // padding-top: calc(var(--ion-safe-area-top) + 10px) !important;
                padding-bottom: calc(var(--ion-safe-area-bottom) + 80px) !important;
            }
        }

        ion-tabs.mob-navbar {
            padding-bottom: calc(var(--ion-safe-area-bottom) + 10px) !important;
        }

        .sidebar,
        .rolloverDialog {
            padding-top: calc(var(--ion-safe-area-top) + 0px) !important;
            padding-bottom: calc(var(--ion-safe-area-bottom) + 0px) !important;
        }

        .notificationDetailDialog,
        .timeOutModalContainer {
            padding-top: env(safe-area-inset-top, 0px) !important;
            padding-bottom: env(safe-area-inset-bottom, 0px) !important;
        }

        .errorPage {
            padding-top: var(--ion-safe-area-top, 0);
            padding-bottom: var(--ion-safe-area-bottom, 0);
        }

        ion-tab-bar {
            // padding-bottom: env(safe-area-inset-bottom);
            padding-bottom: var(--ion-safe-area-bottom);
        }

        ion-content {
            &::part(scroll) {
                padding-top: var(--ion-safe-area-top, 0);
                padding-bottom: var(--ion-safe-area-bottom, 0);
            }
        }

        // .timeOutModalContainer{
        //     padding-top: env(safe-area-inset-top, 0px) !important;
        //     padding-bottom: env(safe-area-inset-bottom, 0px) !important;
        // }
    }



    .plt-iphone.plt-ios.plt-hybrid {
        #myChart .mob-chart-container {
            padding-top: 20px;
        }
    }

    // end:: ios fixes

    // begin:: android fixes
    /* Apply safe-area padding for Android devices (using Platform-specific classes from Ionic) */
    .platform-android,
    .plt-android {

        // .notification-bar {
        //     padding-top: calc(var(--ion-safe-area-top) + 10px) !important;
        // }

        // ion-tabs.mob-navbar {
        //     padding-bottom: calc(var(--ion-safe-area-bottom) + 10px) !important;
        // }

        // .sidebar,
        // .rolloverDialog {
        //     padding-top: calc(var(--ion-safe-area-top) + 0px) !important;
        //     padding-bottom: calc(var(--ion-safe-area-bottom) + 0px) !important;
        // }

        // .notificationDetailDialog,
        // .timeOutModalContainer {
        //     padding-top: env(safe-area-inset-top, 0px) !important;
        //     padding-bottom: env(safe-area-inset-bottom, 0px) !important;
        // }

        // .errorPage {
        //     padding-top: var(--ion-safe-area-top, 0);
        //     padding-bottom: var(--ion-safe-area-bottom, 0);
        // }

        // ion-tab-bar {
        //     // padding-bottom: env(safe-area-inset-bottom);
        //     padding-bottom: var(--ion-safe-area-bottom);
        // }
    }

    // end:: android fixes
}

@media (max-width: 300px) {
    .mobile-card {
        width: 50%;
    }
}
@mixin displayFlex($direction, $justify-content, $align-items) {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
    -webkit-justify-content: $justify-content;
    justify-content: $justify-content;
    -webkit-align-items: $align-items;
    align-items: $align-items;

}

@mixin displayFlexWithGap($direction, $justify-content, $align-items, $gap) {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
    -webkit-justify-content: $justify-content;
    justify-content: $justify-content;
    -webkit-align-items: $align-items;
    align-items: $align-items;
    gap: $gap;
}

@mixin transitionAllEaseInOut {
    transition: all 0.5s ease-in-out;
}

@mixin setHeightWidth($height, $width) {
    height: $height;
    width: $width;
}

@mixin imageSettings {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
// ----------------------------------------------------------
//Rebranding

// begin:: TT-Hoves-Pro font

@font-face {
    font-family: "TT-Hoves-Pro-Light";
    src: url("../assets/webfonts/TTHovesPro/TT-Hoves-Pro-Light.ttf") format("truetype");
}

@font-face {
    font-family: "TT-Hoves-Pro-Regular";
    src: url("../assets/webfonts/TTHovesPro/TT-Hoves-Pro-Regular.ttf") format("truetype");
    // font-weight: 400;
}


@font-face {
    font-family: "TT-Hoves-Pro-DemiBold";
    src: url("../assets/webfonts/TTHovesPro/TT-Hoves-Pro-DemiBold.ttf") format("truetype");
    // font-weight: 600;
}


@font-face {
    font-family: "TT-Hoves-Pro-Bold";
    src: url("../assets/webfonts/TTHovesPro/TT-Hoves-Pro-Bold.ttf") format("truetype");
    // font-weight: 700;
}

// end:: TT-Hoves-Pro font

// begin:: Butler font

@font-face {
    font-family: "Butler_Regular";
    src: url("../assets/webfonts/Butler/Butler_Regular.otf") format("opentype");
    // font-weight: 400;
}

@font-face {
    font-family: "Butler_Medium";
    src: url("../assets/webfonts/Butler/Butler_Medium.otf") format("opentype");
    // font-weight: 500;
}

@font-face {
    font-family: "Butler_Bold";
    src: url("../assets/webfonts/Butler/Butler_Bold.otf") format("opentype");
    // font-weight: 700;
}

@font-face {
    font-family: "Butler_ExtraBold";
    src: url("../assets/webfonts/Butler/Butler_ExtraBold.otf") format("opentype");
    // font-weight: 800;
}

// end:: Butler font

// begin:: Kredit font
@font-face {
    font-family: "Kredit_front";
    src: url("../assets/webfonts/Kredit-Front/kredit-regular.ttf") format("truetype");
    // font-weight: 800;
}

// end:: Kredit font



// ----------------------------------------------------------



:root {
    --primary-regular-font: 'TT-Hoves-Pro-Regular', sans-serif;
    --primary-light-font: 'TT-Hoves-Pro-Light', sans-serif;
    --primary-bold-font: 'TT-Hoves-Pro-Bold', sans-serif;
    --primary-demiBold-font: 'TT-Hoves-Pro-DemiBold', sans-serif;

    --secondary-regular-font: 'Butler_Regular', sans-serif;
    --secondary-medium-font: 'Butler_Medium', sans-serif;
    --secondary-bold-font: 'Butler_Bold', sans-serif;
    --secondary-extraBold-font: 'Butler_ExtraBold', sans-serif;

    --ternary-regular-font: 'Kredit_front', sans-serif;
}
:root {
    // --primary: #f1f2f6;
    // --secondary: #2a292e;
    // --secondary2: #070707;
    // --ternary1: #2373B1;
    // --ternary2: #1E649B;

    // --ternary4: #effafe;
    // --quaternary: #ffffff;
    // --quinary: #1C5C8D;
    // --fadedternary4: #64AAE1;
    // -------------------------------
    // General colors
    --green1: #60B664;
    --green2: #459B48;
    --danger1: #F7173C;
    --danger2: #DB0F30;
    --blue: #41BEEE;
    --blue2: #3BC0EF;

    // --grey1: #474747;
    // --grey2: #828282;

    // --grey4: #90a2b1;
    // --grey5: #e9ecef;
    // --grey6: #7C7B7B;
    // --grey7: #989898;
    // --grey8: #8CA9BE;
    // --grey9: #F5F5F5;
    // --grey10: #f1f7fc;
    // --grey11: #859098;


    // --grey9: #F5F5F5;
    // --senary-bg-shadow: #72727236;


    // General properties
    --logo-color: #ffffff;

    --secondary-bg: #2a292e;
    // --ternary-bg: #2373B1;
    --quaternary-bg: #155F98;
    --secondary: #2b73b0;
    --secondary-card-bg: var(--light-blue);






    --success: #51a754;
    --fail: red;
    --tooltipDark: #525252;

    // Dashboard css


    --contentAreaHead: #7C7B7B;
    --primaryPageHead2: #1E649B;
    --secondaryLinks: #095D7B;
    --paymentLinks: #095B7B;
    --headerIconsBg: #D8F0FB;




    --dashboard-status: #ffffff;


    --table-arrow: #095B7B;
    --font-faded: #525666;

    --font-faded3: #8A9BA9;
    --fadedBg: #2373B114;
    --currencyBg: #F5F7F9;


    /*--------------------
       Login
    --------------------*/
    --login1: #3BC0EF;
    --login2: #309BD1;
    --login3: #2373B1;
    --login4: #A5E5FC;
    --login5: #6BD7FF;
    --login6: #F5F7F9;
    --pwdToggle: #a0a0a0;
    --loginBorder: #DEDEDE;
    --loginBorderFocus: #575757;
    --loginBorderError: #db0505;



    /*--------------------------
        Walkthrough Screens
    --------------------------*/
    --walkthrough1: #2882C8;
    --walkthrough2: #216EAB;








    // ----------------------------------------------------------
    //Rebranding

    --primaryColor: #00122B;
    --primaryColorWithOpacity5: rgba(0, 18, 43, 0.5);
    --primaryColorWithOpacity7: rgba(0, 18, 43, 0.7);
    --primaryColor2: #010F21;
    --secondaryColor: #0040BA;
    --secondaryColor2: #0041BA;
    --secondaryColor2WithOpacity8: rgba(0, 65, 186, 0.8);
    --secondaryColor3: #0127A5;
    --secondaryColor4: #0228AB;
    --ternaryColor: #04C49E;
    --ternaryColorWithOpacity8: rgba(4, 196, 158, 0.8);
    --ternaryColor2: #e0fdf9;
    --quaternaryColor: #B0ECDF;
    --quinaryColor: #A4C3FF;
    --senaryColor: #94B4FF;





    // Sidebar
    --sidebar-bg1: var(--primaryColor2);
    --sidebar-bg2: #0127A5;

    // Colors
    --white: #ffffff;
    --whiteWithOpacity5: rgba(255, 255, 255, 0.5);
    --whiteWithOpacity6: rgba(255, 255, 255, 0.6);
    --grey: #7c7b7b;
    --grey2: #7F8895;
    --grey2WithOpacity5: rgba(127, 136, 149, 0.35);
    --grey3: #707070;
    --grey4: #C2CDE2;
    --grey5: #DCE6FA;
    --grey6: #0041BA1A;
    --grey7: #EAEDEF;
    --grey8: #E9E9E9;
    --grey9: #8CA9BE;
    --grey10: #F5F5F5;
    --grey11: #F4F7F9;
    --grey12: #EEEEEE;
    --grey13: #7A7F94;
    --grey14: #A4A4A4;
    --grey15: #F2F2F2;
    --grey16: #DEDEDE;
    --lightGreen: #E6F9F6;
    --light-blue: #EDF3FE;
    --light-blue2: #f2f6ff;

    // Borders
    --border1: #EEF4FF;

    // Calendar Status
    --green3: #358440;
    --yellow: #D18708;
    --fail2: #AB2009;

    // General
    --primary-btn-bg: var(--ternaryColor);
    --primary-btn-text: var(--white);
    --primary-btn-shadow: #0000003B;
    --secondary-btn-bg: var(--secondaryColor);
    --grayed-btn-bg: var(--grey7);
    --menuLinks: #FFFFFF;
    --menuHover: var(--ternaryColor);
    --primary-header-profiler-color: var(--primaryColor);
    --metric-img-bg: var(--secondaryColor);
    --primary-breadcrumb-color: var(--secondaryColor);
    --secondary-breadcrumb-color: var(--primaryColor);
    --primary-bg: #EEF4FF;
    --body-bg: var(--primary-bg);
    // --primary-help-text: #7F8895;
    --primary-help-text: #4F555F;
    --secondary-help-text: var(--primaryColorWithOpacity7);
    --primary-footer-text: #00122B;
    --primary-footer-textWithOpacity5: rgba(0, 18, 43, 0.5);
    --primaryPageHead: var(--secondaryColor);
    --secondary-footer-text: #80DBFB;
    --ternary-footer-text: var(--whiteWithOpacity6);
    --sidebar-footer-text: #BECFEA;
    --notificationBar-title: var(--secondaryColor2);
    --badge: #DB1414;
    --notificationBar-text: #474747;
    --primary-card-bg: #FFFFFF;
    --mobile-footer-bg: #ffffff;
    --mobileTabHeight: 76px;

    --contentAreaText: var(--primaryColor);
    --ternary3: var(--secondaryColor2);
    --font-faded2: #767B91;
    --primary-border: #A4A4A466;
    --secondary-border: #1B537E;
    --ternary-border: #CBDCE8;
    --quaternary-border: #DEE2E6;
    --muted-border: #A4A4A499;
    --knobSlider1: #010F21;
    --knobSlider2: #0127A5;
    --primaryCheckbox: var(--secondaryColor);

    // Shadows
    --primary-bg-shadow: #2373B14F;
    --secondary-bg-shadow: #0000001F;
    --ternary-bg-shadow: #0000001A;
    --quaternary-bg-shadow: #09243933;
    --quinary-bg-shadow: #00000014;
    --senary-bg-shadow: #72727236;

    // Size Variables
    --virtualCardPadLeft: 34px;
    --virtualCardPadRight: 28px;

    --safe-top: calc(env(safe-area-inset-top) + 10px);



    /*---------------------
        Disabled elements
    ---------------------*/
    --disabledBg: #cccccc;
    --disabledText: #666666;
    --disabledBorder: #999999;


    // Responsive Variables

    --xxl-device: 'only screen and (min-width: 1600px)';
    --xl-device: 'only screen and (min-width: 1500px) and (max-width: 1599px)';
    --laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1499px)';
    --desktop-device: 'only screen and (min-width: 992px) and (max-width: 1199px)';
    --tablet-device: 'only screen and (min-width: 768px) and (max-width: 991px)';
    --large-mobile: 'only screen and (max-width: 767px)';
    --small-mobile: 'only screen and (max-width: 575px)';
    --extra-small-mobile: 'only screen and (max-width: 479px)';
    --extra-small-mobile2: 'only screen and (max-width: 300px)';

}
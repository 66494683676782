@import '_mixins';

// begin: Form Input CSS
.myMatFormFieldCustom {
    margin-bottom: calc((20/16) *1rem);
    @include transitionAllEaseInOut;
    --mdc-outlined-text-field-outline-width: 1px;
    --mdc-outlined-text-field-outline-color: var(--loginBorder);
    --mdc-outlined-text-field-container-shape: 8px;
    --mdc-outlined-text-field-hover-outline-color: var(--loginBorderFocus);
    --mdc-outlined-text-field-focus-outline-color: var(--loginBorderFocus);
    --mdc-outlined-text-field-error-focus-outline-color: var(--loginBorderError);
    --mdc-outlined-text-field-error-hover-outline-color: var(--loginBorderError);
    --mdc-outlined-text-field-error-outline-color: var(--loginBorderError);

    // height: 52px;
    &:last-of-type {
        margin-bottom: calc((28/16) * 1rem);
    }

    @media screen and (min-width:1600px) {
        margin-bottom: calc((30/16) * 1rem);
    }

    .mat-mdc-text-field-wrapper {

        .mat-form-field-flex,
        .mat-mdc-form-field-flex {
            // padding: 0 0;
            border-radius: calc((8/16)*1rem);

            .mdc-notched-outline {
                box-shadow: inset 3px 3px 6px var(--quinary-bg-shadow);
                border-radius: inherit;
            }

            .mat-mdc-form-field-infix {
                min-height: 52px !important;
                padding-top: 14px;
                padding-bottom: 14px;
            }

            .iconBox {
                // @include setHeightWidth(auto, 40px);
                @include setHeightWidth((16/16)+rem, (16/16)+rem);
                padding: 0px;
                overflow: hidden;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                background: transparent;
                border-radius: (6/16)+rem;
                opacity: 1;
                margin-right: (0/16)+rem;

                img {
                    @include imageSettings;
                }
            }

            .iconBoxSuffix {
                cursor: pointer;
                width: (25/16)+rem;
                margin: 0 auto;
                text-align: center;
                color: var(--pwdToggle);
                @include transitionAllEaseInOut;
                padding: 12px;
                box-sizing: content-box;
            }

            .mat-datepicker-toggle {
                button {
                    padding: 0;
                    text-align: right;

                    svg {
                        width: 18px;
                        height: 18px;
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            input:-internal-autofill-selected {
                background-color: transparent !important;
            }

            input {
                font-size: 15px;
                line-height: 1.5;
                font-family: var(--primary-regular-font);
                color: var(--primaryColorWithOpacity5);

                &:placeholder-shown {
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &::placeholder {
                    opacity: 1;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }


            }

            input:focus,
            select.form-control:focus {
                box-shadow: none;
                border-bottom: transparent !important;
            }
        }
    }

    &.referAFriend {
        width: 100%;
        --mdc-outlined-text-field-outline-color: transparent;
        --mdc-outlined-text-field-disabled-outline-color: transparent;

        .mat-mdc-text-field-wrapper {

            .mat-form-field-flex,
            .mat-mdc-form-field-flex {
                background-color: var(--light-blue);

                .mdc-notched-outline {
                    box-shadow: none;
                }

                #referralLink {
                    color: var(--primaryColorWithOpacity5);
                    font-style: normal;
                    font-variant: normal;
                    font-size: calc((12/16)*1rem);
                    line-height: calc((21/16)*1rem);
                    font-family: var(--primary-regular-font) !important;

                    @media screen and (min-width: 1600px) {
                        font-size: calc((15/16)*1rem);
                    }
                }
            }
        }

        &.modalField {
            .mat-mdc-text-field-wrapper {

                .mat-form-field-flex,
                .mat-mdc-form-field-flex {
                    padding: 0 20px;
                }
            }
        }
    }
}